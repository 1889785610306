import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { HeadingContainer, Text } from '../components/common/typography'
import AppLayout from '../components/layouts/appLayout'
import GatsbyImage from 'gatsby-image'
import {
  BLACK_COLOR,
  BLACK_COLOR_RGB,
  PRIMARY_COLOR,
} from '../components/common/color'
import bp from '../components/common/breakpoints'
import SEO from '../components/seo'
import { background, backgroundColor } from 'styled-system'

const PartnersContainer = styled.div`
  display: grid;
  row-gap: 2rem;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: 0;
  padding-bottom: 6rem;
  box-sizing: border-box;

  ${bp[2]} {
    width: 90%;
  }

  ${bp[3]} {
    width: 100%;
    max-width: 1200px;
  }
`

const PartnersCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr;
  row-gap: 0.5rem;
  padding: 1.5rem 0;
  background: linear-gradient(
    180deg,
    rgba(234, 234, 234, 0) 0%,
    rgba(234, 234, 234, 0.4) 95.83%
  );
  border-radius: 20px;

  ${bp[1]} {
    padding: 0;
    grid-template-columns: 200px 1fr;
    grid-template-rows: 180px;
    column-gap: 1rem;
    row-gap: unset;
    border-radius: 100px 0px 0px 100px;
  }

  ${bp[2]} {
    column-gap: 2rem;
    grid-template-columns: 220px 1fr;
  }

  ${bp[3]} {
    column-gap: 4rem;
  }
`

const Image = styled(GatsbyImage)`
  align-self: center;
  justify-self: center;

  ${bp[1]} {
    justify-self: end;
  }
`

const PartnersDescription = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  row-gap: 0.5rem;

  ${bp[1]} {
    justify-items: unset;
  }
`

const PartnersTitle = styled.h3`
  align-self: flex-end;
  text-align: center;
  margin: 0;
  color: ${BLACK_COLOR};
  text-transform: uppercase;

  ${bp[0]} {
    text-align: unset;
  }
`

const TextHighLight = styled.span`
  grid-area: text;
  color: ${PRIMARY_COLOR};
`

const TextDescription = styled(Text)`
  align-self: center;
  width: 90%;
  font-weight: 400;
  color: ${BLACK_COLOR_RGB(0.6)};

  ${bp[1]} {
    width: 95%;
    align-self: flex-start;
  }

  ${bp[2]} {
    width: 90%;
  }

  ${bp[3]} {
    max-width: 800px;
  }
`

const arizonaPartnerStyle = {
  backgroundColor: '#1c1e25',
  borderRadius: '5px',
}

const PartnersPage = ({ data }) => {
  return (
    <AppLayout>
      <SEO title="Our Partners" canonical="partners" />
      <HeadingContainer title="Our Partners" />
      <PartnersContainer>
        <PartnersCard>
          <Image fixed={data.mongoDb.fixed} />
          <PartnersDescription>
            <PartnersTitle>
              We are a <TextHighLight>MongoDB</TextHighLight> COE
            </PartnersTitle>
            <TextDescription>
              We are happy to announce that we are a MongoDB partner and we can help your organization adopting/adapting and enhancing your MongoDB code to better suit your business needs.
            </TextDescription>
          </PartnersDescription>
        </PartnersCard>
        <PartnersCard>
          <Image fixed={data.awsPartner.fixed} />
          <PartnersDescription>
            <PartnersTitle>
              We are <TextHighLight>Amazon</TextHighLight> Partners!
            </PartnersTitle>
            <TextDescription>
              Contact us for help with your <a href="https://aws.amazon.com/">AWS</a> stack if you need help setting
              up, configuring and maintaining AWS resources ( EC2, S3, VPC,
              CloudFront, RDS ,etc)
            </TextDescription>
          </PartnersDescription>
        </PartnersCard>
        <PartnersCard>
          <Image fixed={data.salesforcePartner.fixed} />
          <PartnersDescription>
            <PartnersTitle>
              We are <TextHighLight>SalesForce</TextHighLight> Partners!
            </PartnersTitle>
            <TextDescription>
              We are glad to be part of the <a href="https://appexchange.salesforce.com/">Salesforce</a> partnership community! Please contact us for your consulting needs with Salesforce related tasks (Administration, Consulting, Development & Training).
            </TextDescription>
          </PartnersDescription>
        </PartnersCard>
        <PartnersCard>
          <Image fixed={data.gitlabPartner.fixed} />
          <PartnersDescription>
            <PartnersTitle>
              We are <TextHighLight>Gitlab Technology</TextHighLight> Partners!
            </PartnersTitle>
            <TextDescription>
              Alio IT is a <a href="https://about.gitlab.com/partners/technology-partners/#technology-partners">Gitlab</a> Technology Partner with their premier Product Management
              application linked currently to Gitlab <a href="https://www.gitdone.io/">(Gitdone.io)</a> can be used to offer a more global
              or holistic product management and road-mapping tool.
            </TextDescription>
          </PartnersDescription>
        </PartnersCard>
        <PartnersCard>
          <Image fixed={data.googlePartner.fixed} />
          <PartnersDescription>
            <PartnersTitle>
              We are <TextHighLight>Google Gloud</TextHighLight> Partners!
            </PartnersTitle>
            <TextDescription>
              Contact us for help with your <a href="https://cloud.google.com/">Google</a> Cloud stack if you need help setting
              up, configuring and maintaining GCP resources (Compute Engine,
              Cloud Storage, CloudSQL,BigQuery, etc)
            </TextDescription>
          </PartnersDescription>
        </PartnersCard>
        <PartnersCard>
          <Image fixed={data.microsoftPartner.fixed} />
          <PartnersDescription>
            <PartnersTitle>
              We are <TextHighLight>Microsoft</TextHighLight> Partners!
            </PartnersTitle>
            <TextDescription>
              Contact us for help with your <a href="https://www.microsoft.com/en-us/dynamics-365">Dynamics</a> 365 and any custom .NET development needs your company has!
            </TextDescription>
          </PartnersDescription>
        </PartnersCard>
        <PartnersCard>
          <Image fixed={data.arizonaTechnologyCouncilPartner.fixed} style={arizonaPartnerStyle} />
          <PartnersDescription>
            <PartnersTitle>
              We are part of the <TextHighLight>Arizona Technology Council</TextHighLight>
            </PartnersTitle>
            <TextDescription>
              We are proud members of the broader Arizona blossoming technological <a href="https://www.aztechcouncil.org/">community</a> bringing highly innovative solutions to businesses in the US and the state of Arizona.
            </TextDescription>
          </PartnersDescription>
        </PartnersCard>
      </PartnersContainer>
    </AppLayout>
  )
}

export const query = graphql`
  query {
    gitlabPartner: imageSharp(
      fixed: { originalName: { eq: "gitlab.png" } }
    ) {
      fixed(jpegQuality: 100, quality: 100, width: 100) {
        ...GatsbyImageSharpFixed
      }
    }
    googlePartner: imageSharp(
      fixed: { originalName: { eq: "google-cloud-partner.png" } }
    ) {
      fixed(jpegQuality: 100, quality: 100, width: 160) {
        ...GatsbyImageSharpFixed
      }
    }
    salesforcePartner: imageSharp(
      fixed: { originalName: { eq: "salesforce.png" } }
    ) {
      fixed(jpegQuality: 100, quality: 100, width: 160) {
        ...GatsbyImageSharpFixed
      }
    }
    awsPartner: imageSharp(fixed: { originalName: { eq: "aws-partner.png" } }) {
      fixed(jpegQuality: 100, quality: 100, width: 160) {
        ...GatsbyImageSharpFixed
      }
    }
    microsoftPartner: imageSharp(fixed: { originalName: { eq: "microsoft-partner.png" } }) {
      fixed(jpegQuality: 100, quality: 100, width: 160) {
        ...GatsbyImageSharpFixed
      }
    }
    arizonaTechnologyCouncilPartner: imageSharp(fixed: { originalName: { eq: "arizona-technology-council-partner.png" } }) {
      fixed(jpegQuality: 100, quality: 100, width: 160) {
        ...GatsbyImageSharpFixed
      }
    }
    mongoDb: imageSharp(fixed: { originalName: { eq: "mongo_db.png" } }) {
      fixed(jpegQuality: 100, quality: 100, width: 160) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export default PartnersPage
